:root {
  --black: #1C242C;
  ;
  --white: #ffffff;
  --mainColor: #f4f4ff;
  --textColor: #434547;
  --themeColor: #3176d2;
  --gray: #7c7c7c;
}

@font-face {
  font-family: Montserrat;
  src: url(../src/assets/Fonts/Montserrat/Montserrat-VariableFont_wght.ttf);
}

* {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

body {
  background-color: var(--mainColor);
}

li {
  list-style: none;
}

/* navbar */

.main_nav_div {
  background-color: var(--mainColor);
  position: fixed;
  top: 0%;
  z-index: 99999;
  width: 100%;
}

.main_nav {
  color: var(--textColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  position: relative;
}

.logo_div {
  width: 279px;
  margin-left: 36px;
}

.logo_text {
  color: var(--textColor) !important;
  text-decoration: none;
  cursor: pointer;
}

.logo_div>img {
  width: 60%;
  position: relative;
  z-index: 99999999999999;
}

.nav_ul {
  display: flex;
  align-items: center;
}

.nav_ul>li {
  margin: 0px 20px;
  font-weight: 400;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  font-size: 14px;
  z-index: 99999999;
}

.mobile_nav {
  display: none;
}

.solution_hover_div {
  display: none;
  width: 100%;
  height: auto;
  background-color: var(--mainColor);
  position: absolute;
  top: 60%;
  left: 0%;
  padding: 50px 50px 25px 50px;
  z-index: -1;
}

.service_hover_div {
  display: none;
  width: 100%;
  height: auto;
  background-color: var(--mainColor);
  position: absolute;
  top: 60%;
  left: 0%;
  padding: 50px 50px 25px 50px;
  z-index: -1;
}

.about_hover_div {
  display: none;
  width: 100%;
  height: auto;
  background-color: var(--mainColor);
  position: absolute;
  top: 60%;
  left: 0%;
  padding: 50px 50px 25px 50px;
  z-index: -1;
}

.dropdown_svg_span {
  margin-left: 7px;
}

.nav_link {
  text-decoration: none;
  color: var(--textColor);
}

.active_nav {
  color: var(--themeColor) !important;
}

.contact_li {
  font-size: 15px !important;
  font-weight: 400;
  background-color: var(--themeColor);
  margin: 0px 10px 0px 0px;
  font-size: 15px;
  padding: 10px 26px;
  border-radius: 3px;
  z-index: 99999999;
}

.contact_link {
  color: var(--white);
  text-decoration: none;
}

.dropdown_svg {
  stroke: var(--black);
  fill: var(--black);
}

.list_icon_div {
  background-color: var(--themeColor);
  width: 31px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 10px 2px;
}

.list_icon_div>svg {
  width: 18px !important;
  color: var(--white) !important;
}

.navbar_hover_inner_div {
  display: flex;
  align-items: center;
}

.navbar_hover_inner_div>h3 {
  font-family: Montserrat, Sans-serif;
  font-size: 25px;
  font-weight: 800;
  color: var(--black);
  margin-left: 10px;
}

.navbar_hover_div {
  width: 25%;
}

.navbar_hover_inner_div2 {
  padding: 3px 0px 3px 15px;
  border-left: 2px solid #7C7C7C2E;
  margin: 20px 0px;
}

.navbar_hover_inner_div3 {
  margin: 20px 0px;
}

.navbar_hover_inner_div2>p {
  font-size: 12px;
  line-height: 17px;
  font-weight: 300;
  color: #0E0F10;
}

.navbar_hover_inner_div3>p {
  margin: 20px 0px;
  font-size: 12px;
  line-height: 17px;
  font-weight: 300;
  color: #0E0F10;
}

.navbar_icon_inner_div {
  background-color: var(--themeColor);
  margin: 0px 12px 0px 0px;
  padding: 7px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.navbar_icon {
  color: var(--white) !important;
  fill: var(--white) !important;
  font-size: 17px !important;
}

.navbar_hover_main_div {
  display: flex;
}

.navbar_hover_div2 {
  display: flex;
  /* justify-content: space-around; */
  width: 70%;
  flex-wrap: wrap;
}

.nav_hover_card_p2 {
  font-size: 12px;
  color: var(--white);
  font-weight: 300;
  display: none;
}

.nav_hover_card {
  margin: 0px 10px 20px 10px;
  width: 28%;
  min-height: 100px;
  max-height: auto;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.26);
  padding: 20px 10px;
  border-radius: 9px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.4s ease, transform 0.4s ease;
  transform-origin: center center;

}

.nav_hover_card2 {
  margin: 0px 10px 20px 10px;
  width: 100%;
  min-height: 90px;
  max-height: auto;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.26);
  padding: 20px 10px;
  border-radius: 9px;
  text-align: center;
  transition: background-color 0.4s ease, transform 0.4s ease;
  transform-origin: center center;
}

.nav_hover_text_div>h3 {
  font-size: 18px;
  color: #0E0F10;
  font-weight: 300;
}

.nav_hover_card_p2>h3 {
  font-size: 18px;
  color: var(--white);
  font-weight: 300;
  margin: 10px 0px;
}

.nav_hover_text_div>p {
  color: var(--gray);
  font-weight: 300;
  font-size: 12px;
  margin-top: 10px;
}

.nav_hover_icon_div {
  font-size: 20px !important;
  margin-bottom: 5px !important;
  color: var(--themeColor) !important;
}

.nav_hover_icon_div2 {
  display: block;
  margin-bottom: 5px !important;
}

.nav_hover_icon_div2>img {
  width: 50px;
}

.document_icon {
  fill: var(--themeColor) !important;
  color: var(--themeColor) !important;
}

.nav_hover_card:hover {
  background-color: var(--themeColor);
}

.nav_hover_card:hover .nav_hover_icon_div {
  color: var(--white) !important;
}

.nav_hover_card:hover .nav_hover_icon_div2 {
  display: none;
}

.nav_hover_card:hover .document_icon {
  color: var(--white) !important;
  fill: var(--white) !important;
}

.nav_hover_card:hover .nav_hover_text_div {
  display: none;
}

.nav_hover_card:hover .nav_hover_card_p2 {
  display: block;
}

.nav_hover_card2:hover {
  background-color: var(--themeColor);
}

.nav_hover_card2:hover .nav_hover_icon_div {
  color: var(--white) !important;
}

.nav_hover_card2:hover .nav_hover_icon_div2 {
  display: none;
}

.nav_hover_card2:hover .document_icon {
  color: var(--white) !important;
  fill: var(--white) !important;
}

.nav_hover_card2:hover .nav_hover_text_div {
  display: none;
}

.nav_hover_card2:hover .nav_hover_card_p2 {
  display: block;
}

.navbar_icon_inner_div:hover {
  background-color: var(--white);
}

.navbar_icon_inner_div:hover .navbar_icon {
  color: var(--themeColor) !important;
  fill: var(--themeColor) !important;
}

.list_icon_div:hover>svg {
  color: var(--black) !important;
}

.nav_link:hover {
  color: var(--themeColor);
}

.nav_ul>li:hover .dropdown_svg {
  fill: var(--themeColor) !important;
  stroke: var(--themeColor);
}

.solution_li:hover .solution_hover_div {
  display: block;
}

.service_li:hover .service_hover_div {
  display: block;
}

.about_li:hover .about_hover_div {
  display: block;
}

.contact_li:hover {
  color: #383838 !important;
}

.solution_hover_div:hover {
  display: block;
}

.service_hover_div:hover {
  display: block;
}

.about_hover_div:hover {
  display: block;
}

/* user experience cars */
.user_experience_cards_main {
  display: flex;
}

.user_experience_cards {
  border: 1px solid #ecedee;
  box-shadow: 0 1px 5px 0 rgb(72 73 121 / 15%);
  margin: 50px 1%;
  border-radius: 10px;
  background-color: var(--white);
  text-align: start;
  position: relative;
  height: auto;
  width: 400px;
  padding: 50px 40px;
}

.user_experience_cards:hover {
  border: 1px solid var(--themeColor);
}

.circle_img_div {
  width: 50px;
  height: 50px;
  background-color: #eef2f3;
  border: 8px solid #fff;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
  border-radius: 100%;
  position: absolute;
  right: 20px;
  top: -55px;
  padding: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.user_experience_cards>h3 {
  color: #304049;
  font-size: 22px;
  margin-bottom: 15px;
}

.user_experience_cards>p {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}

.circle_img_div>img {
  height: 50px;
  width: 50px;
}

/* button */

.button_div {
  margin-top: 5%;
  width: 270px;
}
.button_link{
  text-decoration: none;
}

.service_btn_div {
  margin-top: 8%;
  width: 310px;
}

.btn_div {
  background-color: var(--themeColor);
  color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
  padding: 20px 35px 23px 35px;
  display: flex;
  align-items: center !important;
  justify-content: space-evenly;
  cursor: pointer;
  transition: transform 0.5s ease-out;
}

.btn_div:hover {
  transform: translateY(-10px);
  background-color: var(--white);
  color: var(--themeColor);
}

.btn {
  font-size: 15px;
  font-weight: 300;
  margin-top: -4px;
}

.btn_div>span {
  position: relative;
  top: 4px;
}

/* Home */

.main {
  /* background-image: url(./assets/Image/background.webp); */
  /* background-size: cover; */
  /* background-position: center; */
  width: 100%;
  height: 110vh;
}

.text_container {
  width: 91%;
  margin: auto;
  padding-top: 190px;
}

.heading_one {
  font-size: 46px;
  font-weight: 600;
  color: var(--textColor);
}

.heading_two {
  font-size: 46px;
  font-weight: 600;
  color: var(--themeColor);
}

.text_div {
  width: 35%;
}

.text {
  color: var(--gray);
  font-size: 14px;
  font-weight: 500;
  margin-top: 1%;
}

.partner_div {
  margin-top: 5%;
}

.partner_text {
  font-size: 25px;
  font-weight: 700;
  color: var(--textColor);
  display: inline;
}

.change_text {
  font-size: 25px;
  font-weight: 700;
  font-style: normal;
  color: var(--themeColor);
}

.service_container {
  width: 100%;
  padding-bottom: 1%;
  background-color: var(--black);
}

.service_heading_div {
  text-align: center;
  padding-top: 4%;
  color: var(--white);
}

.service_heading_div>h3 {
  font-size: 20px;
  font-weight: 400;
  font-size: 18px;
}

.service_heading_div>p {
  margin-top: 1%;
}

.provide {
  font-weight: 200;
}

.service_div {
  width: 80%;
  margin: 5% auto;
  display: flex;
  justify-content: space-between;
}

.service_text {
  color: var(--white);
  width: 51%;
}

.service_text>h3 {
  font-size: 20px;
  font-weight: 400;
}

.inner_text_div {
  width: 100%;
  border-left: 2px solid rgb(87, 85, 85);
  margin-top: 5%;
}

.icon_and_text_div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15px;
}

.icon_and_text_div>span {
  color: #9b2af4;
}

.icon_and_text_div>p {
  width: 85%;
  font-size: 13px;
  font-weight: 200;
}

.inner_text_div>p {
  margin-top: 15px;
  font-size: 12px;
  font-weight: 300;
}

.inner_text {
  font-weight: 500;
}

.service_card_main {
  width: 45%;
}

.service_card {
  margin-top: 55px;
  width: 100%;
  height: 260px;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: 0px 0px 18px 0px #131313;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.service_card_main:hover .service_card {
  display: none;
}

.blue_service_card {
  margin-top: 55px;
  width: 100%;
  height: 260px;
  background-color: transparent;
  border-radius: 20px;
  box-shadow: 0px 0px 18px 0px #131313;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  background-color: var(--themeColor) !important;
  display: none;
  color: var(--white);
}

.blue_card_text {
  padding-top: 15px;
  font-size: 13px;
}

.service_card_main:hover .blue_service_card {
  display: block;
}

.blue_service_card>div {
  padding-top: 35px !important;
}

.blue_service_card>div>p {
  width: 92%;
  margin: auto;
}

.service_card>div {
  padding-top: 60px;
}

.globe_icon {
  color: var(--themeColor);
}

.white_globe_icon {
  color: var(--white);
}

.web3_text {
  color: var(--white);
  font-weight: 400;
  font-size: 18px;
  margin-top: 20px;
}

.service_card>div>p {
  color: var(--white);
  margin-top: 10px;
  font-weight: 200;
  font-size: 14px;
}

.choose_us_main {
  width: 100%;
  display: flex;
  justify-content: end;
}

.choose_us_container {
  background-color: var(--mainColor) !important;
  width: 96%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.choose_us_left_div {
  width: 45%;
}

.choose_us_heading_div {
  text-align: center;
}

.choose_us_container>div>h3 {
  font-size: 20px;
  font-weight: 400;
  margin-top: 30px;
}

.choose_us_text {
  margin-top: 20px;
  font-size: 14px;
}

.card_section {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
  background-color: transparent;
  border-radius: 10px;
  width: 100%;
}

.choose_us_card_main {
  box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
}

.choose_us_card {
  padding: 25px;
  display: flex;
  text-align: center;
}

.choose_us_card>div {
  width: 25%;
}

.no {
  color: var(--themeColor);
  font-weight: 600;
  font-size: 25px;
}

.choose_us_card_text {
  color: var(--black);
  font-size: 11px;
  font-weight: 300;
  text-transform: uppercase;
  margin-top: 10px;
}

.choose_us_right_div {
  text-align: center;
  width: 51%;
}

.choose_us_right_div>div {
  cursor: pointer;
  height: 120px;
  padding-top: 20px;
}

.choose_us_card_hover_text {
  font-size: 13px;
  font-weight: 200;
  color: var(--white);
  margin-top: 5px;
}

.holistic_heading {
  font-weight: 200;
  margin-top: 10px;
}

.choose_hover_card {
  background-color: var(--themeColor) !important;
}

.client_feedback_container {
  height: auto;
  padding-bottom: 30px;
  width: 100%;
  background-color: var(--black);
  display: block;
}

.client_heading_div {
  text-align: center;
  color: var(--white);
  padding-top: 50px;
}

.client_heading_div>h3 {
  font-size: 18px;
  font-weight: 400;
}

.client_heading_div>p {
  margin-top: 20px;
  font-size: 14px;
}

.slider_main {
  background-color: var(--mainColor);
  margin: 50px auto;
  width: 85%;
  border-radius: 20px;
}

.slick-prev:before {
  color: var(--black) !important;
}

.slick-next:before {
  color: var(--black) !important;
}

.slick-dots li button:before {
  font-size: 9px !important;
  line-height: 40px !important;
}

.slider_div {
  margin: auto;
  width: 78%;
  background-color: transparent;
  text-align: center;
  padding: 50px 0px 60px 0px;
}

.slider_text {
  font-size: 13px;
  font-weight: 200;
}

.slider_img_and_name_div_main {
  display: flex;
  justify-content: center;
}

.slider_img_and_name_div {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.slider_name {
  font-weight: 200;
  font-size: 14px;
  margin-left: 8px;
  text-align: start;
}

.slider_img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 8px;
}

/* technologies */

.technologies_main_din {
  width: 100%;
  height: auto;
  padding-bottom: 50px;
  background-color: var(--mainColor);
  margin-top: 60px;
}

.technologies_main_div2 {
  width: 100%;
  height: auto;
  padding-bottom: 50px;
  background-color: var(--mainColor);
  margin-top: 60px;
}

.technologies_heading_div {
  text-align: center;
}

.technologies_heading_div>h3 {
  font-size: 20px;
  font-weight: 400;
  font-size: 18px;
}

.technologies_heading_div>p {
  margin-top: 20px;
  font-size: 14px;
}

.images_div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 70px;
}

.img_div>img {
  height: auto;
}

.width70 {
  max-width: 70px;
}

.width120 {
  max-width: 120px;
}

.width125 {
  max-width: 125px;
}

.width135 {
  max-width: 135px;
}

.width150 {
  max-width: 150px;
}

.width130 {
  max-width: 130px;
}

.width180 {
  max-width: 180px;
}

.home_work_main {
  width: 100%;
  height: auto;
  background-color: #1a222b;
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;
}

.home_work_main_left {
  width: 42%;
  background-color: transparent;
}

.home_work_main_right {
  width: 51%;
  background-color: transparent;
}

.work_heading_div {
  color: var(--white);
  text-align: center;
  padding-top: 60px;
}

.work_text_div {
  color: var(--white);
  text-align: center;
  width: 80%;
  margin: 35px auto;
}

.work_text_div>p {
  font-size: 14px;
}

.work_heading_div>h3 {
  font-size: 18px;
  font-weight: 400;
}

.work_heading_div>p {
  margin-top: 15px;
  font-size: 14px;
}

.gif_div {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.work_button_div {
  width: 55%;
  margin: 20px auto;
}

.scroll_and_text_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.scroller_div {
  width: 80px;
  margin-top: 72px;
}

.sroller_icon {
  color: var(--themeColor);
  border: 2px solid var(--themeColor);
  border-radius: 5px;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blue_line {
  width: 3px;
  height: 85px;
  margin-left: 23px;
  background-color: var(--themeColor) !important;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.line {
  width: 3px;
  height: 85px;
  background-color: gray;
  margin-left: 23px;
}

.text_and_icon_div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 60px;
  width: 100%;
}

.text_div {
  color: var(--white);
  width: 80%;
}

.work_heading {
  font-size: 14px;
}

.work_text {
  font-size: 12px;
  margin-top: 10px;
}

.icon_div {
  width: 5%;
  color: var(--themeColor);
}

.solution_in_home_main {
  width: 100%;
  background-color: var(--black);
  padding-bottom: 60px;
}

.solution_in_home_inner_div {
  width: 84%;
  margin: auto;
  display: flex;
  justify-content: space- space-between;
  padding-top: 40px;
}

.solution_in_home_inner_div>div {
  width: 50%;
}

.subscribe_main {
  width: 100%;
  padding-bottom: 2%;
  background-color: var(--mainColor);
  display: flex;
}

.subscribe_img_div {
  width: 42%;
}

.subscribe_text_div {
  width: 58%;
  padding-top: 45px;
}

.subscribe_heading {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

.subscribe_img_div>img {
  width: 280px;
  margin: 20px 0px 0px 60px;
}

.subscribe_text {
  color: var(--black) !important;
}

.subscribe_text_and_icons_div {
  margin-top: 20px;
  position: relative;
  right: 130px;
}

.subscribe_input_and_btn_div {
  margin-top: 40px;
}

.subscribe_input {
  width: 55%;
  /* width: 500px; */
  height: 45px;
  border-radius: 3px;
  border-color: rgba(249, 69, 69, 0);
  transition-duration: 0.1s;
  margin-right: 25px;
  color: var(--gray);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  padding-left: 20px;
}

.subscribe_input:focus {
  outline: none;
}

.subscribe_btn {
  background-color: var(--themeColor);
  height: 50px;
  width: 150px;
  border-radius: 5px;
  color: var(--white);
  border: none;
  font-size: 18px;
}

.subscribe_text_and_icons_inner_div {
  border-left: 2px solid rgb(223, 222, 222) !important;
}

.solution_text_and_icons_inner_div {
  display: flex;
  align-items: center;
  width: 94%;
  margin: auto;
  border-left: 2px solid rgb(87, 85, 85);

}

.solution_text_and_icons_inner_div>span {
  color: #9b2af4;
  margin-top: 6px;
  margin-left: 15px;
}

.solution_text_in_home {
  color: var(--white);
  margin-left: 15px;
  font-size: 13px;
  font-weight: 300;
}

.discover_solution_btn {
  width: 55%;
  margin: 30px 16px;
}

/* scrollbar */

.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

.icon-style {
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;
  color: var(--themeColor);
  background-color: var(--white);
  border: 2px solid var(--themeColor);
  animation: movebtn 2.3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}

/* solution */

.solution_text_main_div {
  width: 50%;
}

.solution_text_main_div2 {
  width: 50%;
}

.solution_main_div {
  margin-top: 80px;
  padding: 20px 0px 0px 0px;
  text-align: center;
}

.solution_list_text_div {
  color: #0E0F10;
  font-size: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.solution_list_text_div>h4 {
  font-weight: 200;
}

.solution_list_text_div>b {
  margin-left: 7px;
  font-weight: 400;
  font-family: Montserrat, sans-serif;
}

.solution_list_icon_div {
  padding: 12px;
  width: 32px;
  border-radius: 7px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--themeColor);
}

.solution_inner_div1 {
  display: flex;
  background-color: var(--black);
  padding: 50px 0px 50px 20px;
  margin-top: 20px;
}

.solution_svg_div {
  width: 27%;
  margin: auto;
}

.solution_svg_div>img {
  width: 100%;
}

.solution_list_text_div4 {
  color: var(--white);
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: -5px;
  padding: 0px 0px 3px 15px;
  margin-bottom: 25px;
  margin-left: 25px;
}

.solution_list_text_div4>h4 {
  font-weight: 200;
}

.solution_list_text_div4>b {
  font-weight: 400;
  margin-left: 7px;
  font-family: Montserrat, sans-serif;
}

.solution_list_text_div5 {
  color: var(--white);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px 15px 35px 0px;
  padding: 3px 0px 3px 15px;
}

.solution_list_text_div5>h4 {
  font-weight: 200;
}

.solution_list_text_div5>b {
  font-weight: 400;
  margin-left: 7px;
  font-family: Montserrat, sans-serif;
}

.solution_list_text_div3 {
  color: var(--white);
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding: 3px 0px 3px 15px;
  margin-bottom: 25px;
}

.solution_list_text_div3>h4 {
  font-weight: 200;
}

.solution_list_text_div3>b {
  margin-left: 7px;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
}

.solution_list_text_div2 {
  color: var(--white);
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 3px 0px 3px 15px;
  margin-bottom: 25px;
}

.solution_list_text_div2>h4 {
  font-weight: 200;
  font-size: 18px;
}

.solution_list_text_div2>b {
  margin-left: 5px;
  font-size: 18px;
  font-family: Montserrat, sans-serif;
}

.solution_text_div {
  margin-right: 30px;
  padding: 3px 0px 3px 15px;
  border-left: 2px solid #7C7C7C2E;
  text-align: start;
  color: var(--mainColor);
  font-size: 12px;
  line-height: 17px;
}

.solution_p2 {
  margin-top: calc(12px/2);
  font-weight: 400;
}

.cards_main_div {
  display: flex;
  flex-wrap: wrap;
  padding: 1% 4% 1% 4%;
  margin: 50px auto;
  justify-content: space-between;
}

.card_inner_div {
  width: 23%;
  height: 220px;
  border-radius: 20px;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.26);
  display: grid;
  align-content: space-evenly;
  cursor: pointer;
  margin: 20px 0px;
  transition: background-color 0.4s ease, transform 0.4s ease;
  transform-origin: center center;
}

.card_icon_div {
  margin-top: 20px;
}

.card_h3_div {
  margin-top: -30px;
}

.card_icon {
  font-size: 22px !important;
  color: var(--themeColor) !important;
}

.card_h3_div>h3 {
  font-size: 16px;
  font-weight: 300;
  color: var(--textColor);
}

.card_inner_div>div {
  width: 100%;
}

.solution_list_icon_div>svg {
  font-size: 25px;
  color: var(--white) !important;
}

.schedule_main_div {
  background-color: var(--black);
  padding: 10px;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
}

.schedule_btn_div {
  margin: auto;
  width: 84%;
}

.solution_list_icon_div:hover>svg {
  color: var(--black) !important;
}

.card_inner_div:hover {
  background-color: var(--themeColor);
}

.card_inner_div:hover .card_icon {
  color: var(--white) !important;
}

.card_inner_div:hover .card_h3_div>h3 {
  transform: scale(1.1);
  color: var(--white);
}

/* contact */

.contact_main_Div {
  margin-top: 80px;
  padding: 20px 0px 0px 0px;
}

.contact_icon_and_heading_div {
  text-align: center;
  padding-bottom: 30px;
}

.contact_form_main {
  width: 100%;
  background-color: var(--black);
  padding-bottom: 7%;
}

.contact_heading_div {
  width: 43%;
  margin: auto;
}

.contact_heading_div>h4 {
  font-size: 14px;
  font-weight: 200;
  color: #f3f5f8;
  padding-top: 50px;
  line-height: 22px;
}

.contact_form_div {
  font-size: 14px;
  font-weight: 200;
  color: #f3f5f8;
  padding-top: 10px;
}

.form_main_div {
  width: 60%;
  margin: 60px auto;
}

.form_btn_div {
  width: 30%;
}

.contact_logo {
  width: 90px;
  margin-top: 5px;
  float: left;
}

.form_div {
  background-color: #f3f5f8;
  width: 100%;
  height: auto !important;
  padding-bottom: 110px;
  margin-top: 45px;
  border-radius: 10px;
  box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.5);
}

.inputs_main_div {
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  margin: auto;
  padding-top: 40px;
}

.company_input_div {
  margin-left: 6% !important;
}

.input_div {
  width: 39.5%;
  margin: 2% 4%;
}

.input_div>p {
  font-size: 14px;
  font-weight: 200;
  color: #000000;
}

.input_div>input {
  font-size: 14px;
  height: 45px;
  width: 100%;
  background-color: var(--mainColor);
  border: 1px solid #A5A5A5;
  border-radius: 5px;
  padding-left: 20px;
  margin-top: 10px;
  color: #0E0F10CC;
  font-size: 14px;
  font-weight: 200;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}

.input_div>input:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px #d0d0d0;
}

.star {
  color: red;
}

.text_area_div>p {
  font-size: 14px;
  font-weight: 200;
  color: #000000;
  margin-top: 10px;
}

.text_area_main {
  width: 87.5% !important;
  margin: auto !important;
  padding-top: 0 !important;
}

.text_area_div {
  width: 100%;
}

.text_area {
  font-size: 14px;
  width: 96% !important;
  background-color: var(--mainColor);
  border: 1px solid #A5A5A5;
  border-radius: 5px;
  padding-left: 20px;
  margin-top: 10px;
  color: #0E0F10CC;
  font-size: 14px;
  font-weight: 200;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}

.text_area:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px #d0d0d0;
}

.file_main_div {
  padding-top: 0 !important;
}

.file_div {
  margin: 2% 4%;
}

.file_div>p {
  font-size: 14px;
  font-weight: 200;
  color: #000000;
}

.file {
  margin-top: 10px;
}

.submit_btn_div {
  float: right;
  margin: 20px 50px;
}



.submit_btn_div>button {
  font-weight: 300;
  font-size: 15px;
  color: var(--white);
  background-color: var(--themeColor);
  border: none;
  height: 50px;
  width: 160px;
  border-radius: 8px;
  transition: transform 0.5s ease-out;
}

.submit_btn_div>button:hover {
  cursor: pointer;
  background-color: var(--mainColor);
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  transform: translateY(-10px);
}
.PopupButton {
  font-weight: 300;
  font-size: 15px;
  color: var(--white);
  background-color: var(--themeColor);
  border: none;
  border-radius: 8px;
  cursor: pointer !important;
  height: 50px;
  width: 160px;
  border-radius: 8px;
  transition: transform 0.5s ease-out;
}

/* services */

.cards_main_div2 {
  display: flex;
  flex-wrap: wrap;
  padding: 1% 4% 1% 4%;
  margin: 50px auto;
  /* justify-content: space-between; */
}

.card_inner_div2 {
  text-decoration: none;
  width: 29%;
  height: 300px;
  border-radius: 20px;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.26);
  display: grid;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  margin: 0px 20px 40px 20px;
  transition: background-color 0.4s ease, transform 0.4s ease;
  transform-origin: center center;
}

.app_card_inner_div {
  width: 25%;
  height: 250px;
  border-radius: 20px;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.26);
  display: grid;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  margin: 0px 20px 40px 20px;
  transition: background-color 0.4s ease, transform 0.4s ease;
  transform-origin: center center;
}

.card_icon_div2 {
  margin-top: 50px;
}

.card_h3_div2 {
  margin-top: 20px !important;
}

.card_h3_div2>h3 {
  font-size: 16px;
  font-weight: 300;
  color: var(--textColor);
}

.card_h3_div3 {
  display: none;
  margin-top: 20px !important;
}

.card_h3_div2>p {
  line-height: 17px;
  margin: 20px 0px;
  font-size: 12px;
  color: var(--gray);
  font-weight: 300;
}

.card_inner_div2>div {
  margin: auto;
  width: 50%;
}

.app_card_inner_div>div {
  margin: auto;
  width: 50%;
}

.service_list_text_div3 {
  color: var(--white);
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding: 3px 0px 3px 15px;
  margin-bottom: 25px;
}

.service_card_img_div {
  display: block;
}

.service_card_img_div>img {
  width: 60px;
}

.app_card_img_div {
  display: block;
}

.app_card_img_div>img {
  width: 60px;
  height: 60px;
}

.service_list_text_div3>h4 {
  font-weight: 200;
  margin-left: 40px;
}

.service_list_text_div3>b {
  margin-left: 7px;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
}

.card_inner_div2:hover {
  background-color: var(--themeColor);
}

.app_card_inner_div:hover {
  background-color: var(--themeColor);
}

.app_card_inner_div:hover .app_card_h1 {
  color: var(--white);
}

.app_card_inner_div:hover .app_card_img_div {
  display: none;
}

.card_inner_div2:hover .service_card_img_div {
  display: none;
}

.card_inner_div2:hover .card_h3_div2 {
  display: none;
}

.card_h3_div3 {
  width: 100%;
}

.card_h3_div3>h3 {
  color: var(--white);
  font-size: 16px;
  line-height: 17px;
  font-weight: 500;
  margin-bottom: 15px;
}

.card_inner_div2:hover .card_h3_div3 {
  display: block;
  color: var(--white);
  font-size: 12px;
  line-height: 17px;
  font-weight: 300;
}

/* about */

.about_main_div {
  margin: 100px 0px 0px 0px;
}

.about_inner_div1 {
  width: 60%;
  text-align: center;
  margin: auto;
}

.story_div {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 77%;
  padding: 70px 30px 60px 30px;
}

.about_p {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 200;
  line-height: 20px;
}

.about_inner_div2 {
  margin-top: 40px;
  background-color: var(--black);
}

.about_h4_div {
  color: var(--white);
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.about_h4_div>h4 {
  font-weight: 200;
}

.about_h4_div>b {
  margin-left: 7px;
  font-weight: 700;
  font-family: Montserrat, sans-serif;
}

.about_inner_div3 {
  margin-top: 30px;
}

.about_h4_div2 {
  color: var(--black);
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.about_h4_div2>h4 {
  font-weight: 400;
}

.about_h4_div2>b {
  margin-left: 5px;
  font-weight: 700;
  font-family: Montserrat, sans-serif;
}

.about_h4_div3 {
  color: var(--white);
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.about_h4_div3>h4 {
  font-weight: 200;
}

.about_h4_div3>b {
  margin-left: 7px;
  font-weight: 700;
  font-family: Montserrat, sans-serif;
}

.solution_list_text_div6 {
  color: var(--black);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 15px 0px 20px 0px;
  padding: 3px 0px 3px 15px;
}

.solution_list_text_div6>h4 {
  font-weight: 200;
}

.solution_list_text_div6>b {
  font-weight: 400;
  margin-left: 7px;
  font-family: Montserrat, sans-serif;
}

.about_p1 {
  margin-left: 100px;
  margin-top: 13px;
  color: var(--white);
  font-size: 14px;
  font-weight: 200;
  font-family: Montserrat;
  line-height: 25px;
  text-align: justify;
  width: 70%;
}

.about_img_div>img {
  width: 310px;
  max-width: 310px;
  border-radius: 15px;
}

.about_card_main_div {
  display: flex;
  flex-wrap: wrap;
  width: 89%;
  margin: 38px auto;
}

.about_card_text>h2 {
  margin-top: 30px;
  color: var(--black);
  font-weight: 500;
  font-size: 14px;
}

.about_card_text>p {
  margin: 5px 0px 15px 0px;
  color: #0E0F10;
  font-weight: 300;
  font-size: 12px;
}

.about_card_inner_div {
  width: 25%;
  margin: 20px 0px 40px 0px;
  text-align: center;
}

.about_card_img_div {
  margin: auto;
  width: 60%;
}

.about_card_img_div>img {
  width: 77%;
  border-radius: 10%;
}

.about_card_icon_div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 15px;
}

.about_card_icon_inner {
  border: 1px solid #0E0F10;
  border-radius: 3px;
  margin-right: 12px;
  width: 21px !important;
  height: 21px !important;
  cursor: pointer;
}

.about_div_icons {
  font-size: 10px !important;
  color: var(--black) !important;
}

.about_inner_div4 {
  background-color: var(--black);
  padding: 50px 0px;
}

.about_located_main_div {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.about_located_img {
  width: 35%;
}

.about_located_text_div {
  width: 35%;
  margin-left: 50px;
}

.about_located_img>img {
  width: 100%;
}

.about_located_icon_div {
  padding: 3px 0px 3px 15px;
  border-left: 2px solid #7C7C7C2E;
}

.about_located_inner_text {
  color: var(--white);
  font-size: 12px;
  font-weight: 300;
  margin: 15px 0px;
}

.about_inner_icon_div {
  display: flex;
  margin: 10px 0px;
}

.about_inner_p_div {
  display: flex;
}

.about_located_icon {
  color: #9B2AF4 !important;
}

.about_inner_p_div>p {
  font-size: 12px;
  margin-left: 10px;
  font-weight: 300;
  color: var(--white);
}

.about_inner_p_div2>p {
  font-size: 12px;
  margin-left: 10px;
  font-weight: 300;
  color: var(--textColor);
}

.about_inner_p_div>img {
  height: 15px;
  width: 15px;
  margin-left: 5px;
}

.img_div2 {
  width: 20%;
  text-align: center;
}

.images_div2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  /* margin-left: 70px; */
  margin-top: 70px;
}

.swit_div {
  display: flex;
  align-items: center;
}

.swit_div>p {
  font-size: 14px;
  color: var(--textColor);
  font-weight: 200;
  margin-left: 5px;
}

.swit_div>img {
  width: 23px;
  border-radius: 40px;
}

.navbar_hover_div3 {
  width: 30%;
  margin: 0px 40px;
}

.navbar_hover_div4 {
  width: 30%;
  margin: 0px 30px;
}

.about_card_icon_inner:hover {
  background-color: var(--themeColor);
}

.about_card_icon_inner:hover .about_div_icons {
  color: var(--white) !important;
}

/* drawer */

.css-1u2w381-MuiModal-root-MuiDrawer-root {
  z-index: 9999999999999 !important;
}

.drawer_div,
.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  background-color: var(--mainColor) !important;
}

.menu_btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-top: -6px !important;
  margin-right: 48px !important;
}

.menu_icon {
  color: #333333 !important;
  font-size: 38px;
}

.mobile_nav_ul {
  margin-top: 50px;
}

.mobile_nav_ul>li {
  font-weight: 400;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
}

.close_icon_div {
  text-align: end;
  margin: 10px 10px 10px 0px;
}

.close_icon {
  cursor: pointer;
  color: #777777;
  font-size: 20px;
}


.mobile_link {
  color: var(--textColor);
  text-decoration: none;
  width: 80%;
  height: 100%;
  padding: 20px 0px;
}

.right_arror_icon {
  color: #333333;
  font-size: 20px !important;
}

.mobile_nav_ul>li>button {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.close_arrow_icon_div {
  display: flex;
  margin: 10px 10px 10px 10px;
  justify-content: space-between;
  align-items: center;
}

.menu_btn:hover .menu_icon {
  color: var(--themeColor) !important;
}

.mobile_nav_ul>li:hover {
  background-color: var(--themeColor);
}

.mobile_nav_ul>li:hover .right_arror_icon {
  color: var(--white);
}

.mobile_nav_ul>li:hover .mobile_link {
  color: var(--white);
}

/* tabs */

.tabs {
  margin-left: 44px;
  display: flex;
  justify-content: center;
  position: relative;
}

.tab_text {
  font-size: 11px;
  margin-top: 8px;
  font-weight: 700;
  transition: color 0.5s;
}

.tabs_main_div {
  width: 700px;
  margin: 60px auto 0px;
}

.tab_inner {
  margin-top: 25px;
  margin-right: 26px;
  width: 80px;
  cursor: pointer;
  text-align: center;
}

.tab_icon_div {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .15);
  background-color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  transition: background-color 0.5s, color 0.5s;
}

.tabs_inner_div {
  display: inline-flex;
  align-items: center;
}

.active .tab_text {
  color: var(--themeColor);
}

.tab_inner:hover .tab_text {
  color: var(--themeColor);
}

.tab_inner:hover .tab_icon_div {
  background-color: var(--themeColor);
  color: var(--mainColor);
}

.tab_icon {
  width: 25px;
  height: 25px;
}

.active .tab_icon_div {
  background-color: var(--themeColor);
  color: var(--mainColor);
}

.dotted_line_div {
  display: flex;
  width: 700px;
  position: absolute;
}

.dotted_line {
  border-top: 2px dashed #cfd1d1;
  margin: 10px 0;
  width: 100%;
}

.tabs_circle {
  background-color: #d2d3d4;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  margin: auto 5px;
}

.technologies_main_div {
  margin: 20px 0px 40px;
  text-align: center;
}

.technologies_h2 {
  margin: 10px 0px;
  font-weight: 500;
}

.technologies_p {
  width: 40%;
  font-family: 300;
  font-size: 13px;
  margin: 10px auto;
}

.technologies_card_main_div {
  margin-top: 25px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.technologies_card_inner_div {
  background: var(--mainColor);
  text-align: center;
  cursor: pointer;
  min-height: 290px;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ecedee;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.technologies_card_inner_div>img {
  max-width: 150px;
}

.technologies_card_inner_div:hover {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .15);
  transform: scale(1.02);
}

.tabs2 {
  display: none;
  margin: 30px 0px;
  width: 100%;
  text-align: center;
}

.tab_inner2 {
  cursor: pointer;
  padding: 10px 0px;
  width: 60%;
  font-size: 15px;
  margin: 14px auto;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .15);
  background-color: var(--mainColor);
  transition: background-color 0.5s, color 0.5s;
  border-radius: 7px;
}

.active2 {
  background-color: var(--themeColor);
  color: var(--white);
}

/* footer */

.footer_main_div {
  background-color: #161617;
  padding: 70px 0px;
}

.footer_inner_div {
  display: flex;
  width: 90%;
  margin: auto;
}

.footer_h3 {
  color: var(--white);
  margin: 15px 0px 20px 0px;
  font-family: "Montserrat", Sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.footer_ul>li {
  margin: 8px 0px;
  color: #FFFFFF87;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
}

.footer_logo_div {
  width: 50%;
}

.footer_logo_div>img {
  width: 100%;
}

.footer_div1 {
  width: 33%;
}

.footer_p {
  color: #ffffffa6;
  font-size: 13px;
  line-height: 1.5;
  text-align: justify;
  font-weight: 300;
  margin-top: 10px;
}

.footer_div1,
.footer_div2,
.footer_div3,
.footer_div4 {
  margin: 0px 50px;
}

.footer_icon_div {
  margin: 20px 0px;
}

.footer_icon_ul {
  display: flex;
  margin-top: 20px;
}

.footer_a {
  color: var(--gray);
  text-decoration: none;
  font-size: 12px;
}

.footer_icon_inner_div {
  background-color: #484848;
  margin: 0px 12px 0px 0px;
  padding: 7px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.footer-logo {
  color: var(--white);
}

.footer_icon {
  margin-top: 4px !important;
  color: var(--white) !important;
  fill: var(--white) !important;
  font-size: 17px !important;
}

.footer_inner_div2_main {
  background-color: #161617;
  border-top: 1px solid rgb(255 255 255 / 19%);
}

.footer_inner_div2 {
  display: flex;
  width: 83%;
  margin: auto;
  justify-content: space-between;
  padding: 20px;
}

.footer_inner_div2>div>p {
  color: #adadad;
  font-size: 12px;
  display: inline;
}

.zug {
  position: relative;
  bottom: 5px;
}

.plus_icon {
  color: red;
  display: inline;
  position: relative;
  top: 1px;
  left: 10px;
}

.footer_icon_inner_div:hover {
  background-color: var(--white);
}

.footer_icon_inner_div:hover .footer_icon {
  color: var(--themeColor) !important;
  fill: var(--themeColor) !important;
}

.footer_ul>li:hover {
  color: var(--white);
}

.switzerland_img {
  width: 20px;
}

/* How We Works */

.WeWorks_main_Section {
  margin-top: 100px;
  margin-bottom: 100px;
}

.worksMain_heading {
  color: var(--themeColor);
  text-align: center;
}

.work_main_Section {
  margin: 30px 0px 0px 0px;
  display: flex;
}

.working_Girl_div {
  width: 50%;
  height: 459px;
  margin: 20px 0px 20px 20px;
}

.meetImg {
  background-image: url(./assets/Image/working_girl.jpg);
  border: 10px solid #fff;
  border-radius: 30px;
  margin-bottom: 0;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.How_it_works_text_div {
  width: 50%;
  padding: 10px;
  /* height: 459px; */
  text-align: left;
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.How_it_works {
  color: var(--black);
}

.blue_bottom_line {
  background-color: var(--themeColor);
  height: 5px;
  width: 100px;
  border-radius: 10px;
  margin-top: 10px;
}

.Works_paragraph_section {
  margin-top: 20px;
}

.Works_paragraph {
  color: var(--black);
  margin-bottom: 10px;
}

.ExpertNow_btn {
  border: none;
  background-color: var(--themeColor);
  margin-top: 30px;
  padding: 15px;
  color: var(--white);
  border-radius: 5px;
  cursor: pointer;
}

.ExpertNow_btn:hover {
  background-color: var(--black);
  color: var(--white);
  transition: 0.5s;
}

/* .How_its_work_main_section {
  width: 100%;
  margin-top: 100px;
  height: 100vh;
}

.Man_img_div::before{
  background-image: url(./assets/Image/ManBackgroundImg.jpg);
  
  height: 100%;
  width: 22%;
  
  opacity: 0.6;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.working_girl_img_div{
  width: 50%;
  background-color: var(--mainColor);
  height: 200px;
} */

/* software devlopment */

.development_main_div {
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
}

.development_heading_div {
  animation: movebtn 2.3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}

.development_heading_div>h1 {
  color: var(--themeColor);
  margin: 10px 0px;
}

.development_heading_div>p {
  font-size: 13px;
}

.development_inner_div {
  margin: 80px auto 0px;
  width: 90%;
}

.development_inner_div_2>h4 {
  margin: 10px 0px;
  font-size: 20px;
  font-weight: 400;
  color: var(--themeColor);
}

.development_inner_div_2>h3 {
  font-size: 25px;
  color: #304049;
}

.development_inner_div_2>p {
  margin: 20px 0px 10px;
  font-size: 13px;
}

.development_card_main_div {
  display: flex;
  margin-top: 40px;
}

.development_inner_main_div {
  width: 33.33333333%;
  text-align: start;
  margin: 20px;
}

.development_img_div {
  height: 45px;
  width: 45px;
  margin-bottom: 20px;
}

.development_img_div>img {
  height: 45px;
  width: 45px;
}

.development_card_h1 {
  font-size: 19px;
  margin: 10px 0px;
}

.app_card_main_div {
  display: flex;
  flex-wrap: wrap;
  padding: 1% 4% 1% 4%;
  margin: 50px auto;
  justify-content: space-between;
}

.app_card_h1 {
  font-size: 19px;
  margin: 20px 0px 10px 10px;
}

.development_card_p {
  font-size: 11px;
  line-height: 20px;
}

.development_ecommerce_div {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 50px auto 0px;
  padding: 10px;
}

.development_ecommerce_inner_div {
  text-align: start;
  width: 50%;
}

.development_ecommerce_inner_div>h1 {
  font-weight: 500;
  margin-bottom: 30px;
}

.development_ecommerce_inner_div>p {
  font-size: 14px;
}

.development_ecommerce_inner_div2 {
  width: 50%;
}

.development_ecommerce_inner_div2>img {
  height: 250px;
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-18px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* media query */


@media only screen and (max-width:1200px) {
  .solution_list_text_div3 {
    font-size: 16px;
  }

  .service_list_text_div3 {
    font-size: 16px;
  }

  .solution_list_text_div4 {
    font-size: 13px;
  }

  .contact_heading_div {
    width: 95%;
    margin: auto;
    text-align: center;
  }

  .form_main_div {
    width: 82%;
  }

  .subscribe_main {
    padding-bottom: 4%;
  }

  .discover_solution_btn {
    width: 65%;
  }

  .nav_hover_card {
    width: 42%;
  }
}

@media only screen and (max-width:1000px) {
  .solution_text_main_div2 {
    display: none;
  }

  .menu_btn {
    margin-top: -6px !important;
    margin-right: 13px !important;
  }

  .main_nav {
    padding: 14px 0px;
  }

  .logo_div {
    margin-left: 11px;
  }

  .solution_text_main_div {
    width: 100%;
  }

  .cards_main_div2 {
    display: flex;
    /* justify-content: space-around; */
  }

  .app_card_main_div {
    display: flex;
    justify-content: space-around;
  }

  .story_div {
    display: flex;
    flex-wrap: wrap;
  }

  .story_inner_div {
    margin-top: 50px;
  }

  .about_p1 {
    text-align: center;
    width: 100%;
    margin-left: 0px;
  }

  .cards_main_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .card_inner_div,
  .card_inner_div2,
  .app_card_inner_div {
    width: 43%;
  }

  .solution_list_text_div2 {
    display: none;
  }

  .footer_inner_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0px;
  }

  .mobile_nav {
    display: block;
  }

  .nav_ul {
    display: none;
  }

  .form_main_div {
    width: 95%;
  }

  .home_work_main {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--black);
  }

  .home_work_main_right {
    width: 90%;
    margin: auto;
  }

  .gif_div,
  .card_section {
    display: none;
  }

  .home_work_main_left {
    width: 60%;
    margin: auto;
  }

  .subscribe_main {
    display: block;
    padding-bottom: 5%;
  }

  .subscribe_img_div {
    width: 100%;
    text-align: center;
  }

  .subscribe_img_div>img {
    margin: 0 !important;
  }

  .subscribe_text_div {
    margin: auto;
  }

  .subscribe_input {
    width: 60%;
  }

  .subscribe_text_and_icons_div {
    position: initial;
  }

  .subscribe_text_and_icons_inner_div {
    width: 100% !important;
  }

  .discover_solution_btn {
    width: 75%;
  }

  .work_main_Section {
    display: block;
  }

  .working_Girl_div {
    margin: 0px auto;
    width: 80%;

  }

  .How_it_works_text_div {
    margin: 50px auto;
    width: 80%;
  }
}

@media only screen and (max-width:900px) {
  .subscribe_text_div {
    width: 70%;
  }

  .technologies_card_inner_div {
    width: 25%;
  }

}

@media only screen and (max-width:800px) {
  .development_card_main_div {
    flex-wrap: wrap;
  }

  .development_inner_main_div {
    width: 40%;
  }

  .development_ecommerce_div {
    flex-wrap: wrap-reverse;
  }

  .development_ecommerce_inner_div2 {
    width: 100%;
    margin: 0px 0px 30px;
  }

  .development_ecommerce_inner_div {
    width: 100%;
  }

  .user_experience_cards_main {
    display: block;
  }

  .user_experience_cards {
    margin: 80px auto;
    width: 60% !important;
  }
}


@media only screen and (max-width:750px) {
  .cards_main_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .cards_main_div2 {
    justify-content: center;
  }

  .card_inner_div,
  .card_inner_div2,
  .app_card_inner_div {
    width: 83%;
  }

  .tab_icon_div {
    width: 50px;
    height: 50px;
  }

  .tab_text {
    font-size: 10px;
  }

  .tab_inner {
    margin-right: 9px;
  }

  .technologies_card_inner_div {
    width: 45%;
  }

  .tabs_main_div {
    width: 619px;
  }

  .dotted_line_div {
    width: 619px;
  }

  .footer_div1 {
    width: 100%;
  }

  .footer_div1,
  .footer_div2,
  .footer_div3,
  .footer_div4 {
    margin: 0px 30px;
  }

  .solution_list_text_div {
    font-size: 30px;
  }

  .about_card_main_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .about_card_inner_div {
    width: 43%;
  }

  .about_h4_div3 {
    display: flex;
    justify-content: center;
  }

  .about_located_img {
    display: none;
  }

  .about_located_text_div {
    width: 100%;
    text-align: center;
    margin: 0px 15px;
  }

  .about_inner_div4 {
    padding: 50px 0px 0px;
  }

  .technologies_main_div2 {
    display: none;
  }

  .work_text_div,
  .teachno_none {
    display: none;
  }

  .home_work_main_left {
    width: 80%;
  }

  .work_heading_div {
    padding-top: 0px;
  }

  .choose_us_container {
    width: 100%;
  }

  .choose_us_container {
    display: flex;
    flex-wrap: wrap;
  }

  .choose_us_right_div {
    text-align: center;
    width: 100%;
    margin: 20px 0px;
  }

  .choose_us_left_div {
    width: 100%;
    margin: 30px 0px;
  }

  .service_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .service_card_main {
    width: 100%;
  }

  .service_text {
    width: 100%;
  }

  .service_btn_div {
    margin: 30px auto;
  }

  .subscribe_text_div {
    width: 80%;
  }

  .subscribe_input {
    width: 55%;
  }
}

@media only screen and (max-width:650px) {
  .technologies_card_inner_div {
    width: 50%;
  }

  .tabs2 {
    display: block;
  }

  .tabs_inner_div {
    display: none;
  }

  .tabs_main_div {
    margin-top: 100px;
    width: 100%;
  }

  .development_inner_main_div {
    width: 100%;
  }

  .development_heading_div>h1 {
    font-size: 24px;
  }

  .development_heading_div>p {
    font-size: 11px;
  }

  .development_inner_div_2>h4 {
    font-size: 18px;
  }

  .development_inner_div_2>h3 {
    font-size: 22px;
  }

  .development_inner_div_2>p {
    font-size: 12px;
  }

  .development_ecommerce_inner_div2>img {
    height: 200px;
  }

  .user_experience_cards {
    width: 70% !important;
  }
}

@media only screen and (max-width:600px) {
  .about_card_img_div {
    width: 80%;
  }

  .about_img_div>img {
    width: 254px;
  }

  .heading_one,
  .heading_two {
    font-size: 30px;
    font-weight: 500;
  }

  .text {
    font-size: 13px;
    margin-top: 5%;
  }

  .partner_div {
    display: none;
  }

  .button_div {
    margin-top: 7%;
    width: 250px;
  }

  .btn_div {
    padding: 15px 10px;

  }

  .subscribe_text_div {
    width: 90%;
  }

  .subscribe_input {
    width: 50%;
    height: 40px;
  }

  .subscribe_btn {
    height: 45px;
  }
}

@media only screen and (max-width:500px) {
  .subscribe_text_div {
    width: 95%;
  }

  .subscribe_btn {
    width: 130px;
    font-size: 15px;
  }

  .subscribe_input {
    width: 48%;
    height: 40px;
  }

  .tab_inner2 {
    width: 67%;
    font-size: 12px;
  }
}

@media only screen and (max-width:400px) {
  .footer_icon_inner_div {
    background-color: #484848;
    margin: 12px 12px 12px 0px;
  }

  .footer_icon_ul {
    flex-wrap: wrap;
  }

  .footer_logo_div {
    width: 179px;
  }

  .solution_list_text_div {
    font-size: 25px;
  }

  .solution_list_text_div5 {
    font-size: 12px;
  }

  .subscribe_input_and_btn_div {
    width: 95%;
  }

  .subscribe_input {
    width: 92%;
    height: 45px;
  }

  .subscribe_btn {
    margin-top: 20px;
    width: 100%;
    height: 50px;
    font-size: 20px;
  }.circle_img_div {
    width: 30px;
    height: 30px;
  }.circle_img_div>img {
    height: 29px;
    width: 30px;
}
}

@media only screen and (max-width:350px) {
  .development_ecommerce_inner_div2>img {
    height: 150px;
  }.user_experience_cards {
    width: auto !important;
}
}

.flip-card {
  /* width: 200px;
  height: 200px; */
  perspective: 1000px;

}

.flip-card-inner {
  position: relative;
  /* width: 100%;
  height: 100%; */
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  /* width: 100%;
  height: 100%; */
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}
